import styled from "styled-components";

export const MainSearch = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  text-align: center;
`;

export const NotFound = styled.div``;
