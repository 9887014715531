import styled from "styled-components";

export const Loader = styled.div`
  --height-of-loader: 4px;
  --loader-color: white;
  width: 60%;
  height: var(--height-of-loader);
  border-radius: 30px;
  background-color: white;
  position: relative;

  ::before {
    content: "";
    position: absolute;
    background: var(--loader-color);
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    border-radius: 30px;
    animation: moving 1s ease-in-out infinite;
  }

  @keyframes moving {
    50% {
      width: 100%;
    }

    100% {
      width: 0;
      right: 0;
      left: unset;
    }
  }
`;
