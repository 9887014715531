import React from "react";

class NotFound extends React.Component {
  render() {
    return (
      <div data-testid="page-not-found">
        <p>Not Found</p>
      </div>
    );
  }
}

export default NotFound;
